import { MapLayersState } from 'types';

// actions
const LOAD_START = 'store/map/layers/info/load-start';
const LOAD_ERROR = 'store/map/layers/info/load-error';
const GOT_LAYERS = 'store/map/layers/info/got-layers';
const GOT_LEGEND = 'store/map/layers/info/got-legend';

export type LoadStartAction = ReturnType<typeof startLoading>;
export type LoadErrorAction = ReturnType<typeof errorLoading>;
export type GotLayersAction = ReturnType<typeof gotLayers>;
export type GotLegendAction = ReturnType<typeof gotLegend>;

export type MapLoadInfoAction =
  | LoadStartAction
  | LoadErrorAction
  | GotLayersAction
  | GotLegendAction;

// action creators
export const startLoading = () => ({ type: LOAD_START as typeof LOAD_START });
export const errorLoading = (error: any) => ({ type: LOAD_ERROR as typeof LOAD_ERROR, error });
export const gotLayers = (layers: string[]) => ({ type: GOT_LAYERS as typeof GOT_LAYERS, layers });
export const gotLegend = (legend: Record<string, any>) => ({ type: GOT_LEGEND as typeof GOT_LEGEND, legend });

// reducers
type _Reducer<A extends MapLoadInfoAction = MapLoadInfoAction> = (state: MapLayersState, action: A) => MapLayersState;

const _startLoading: _Reducer<LoadStartAction> = state => ({
  ...state,
  goServiceInfo: {
    allLayers: [],
    symbolRules: {}
  },
});
const _errorLoading: _Reducer<LoadErrorAction> = (state) => ({
  ...state,
  goServiceInfo: {
    ...state.goServiceInfo,
    // error
  }
});

const _gotLayers: _Reducer<GotLayersAction> = (state, { layers }) => ({
  ...state,
  // TODO:  go: filter(state.go, layers),
  goServiceInfo: {
    ...state.goServiceInfo,
    allLayers: layers,
  },
});

const _gotLegend: _Reducer<GotLegendAction> = (state, { legend }) => ({
  ...state,
  goServiceInfo: {
    ...state.goServiceInfo,
    symbolRules: legend,
  },
});


export const infoReducer: _Reducer = (state, action) => {
  switch (action.type) {
    case LOAD_START: return _startLoading(state, action);
    case LOAD_ERROR: return _errorLoading(state, action);
    case GOT_LAYERS: return _gotLayers(state, action);
    case GOT_LEGEND: return _gotLegend(state, action);
    default: return state;
  }
};
