import { all } from 'redux-saga/effects';

import authSaga from './auth';
import { droneSaga } from './drone';
import { kortforsyningenSaga } from './kortforsyningen';
import mapSaga from './map';
import { samlSaga } from './saml';
import searchSaga from './search';

export default function* rootSaga() {
  yield all([
    authSaga(),
    droneSaga(),
    kortforsyningenSaga(),
    mapSaga(),
    samlSaga(),
    searchSaga(),
  ]);
}
