import CssBaseline from '@material-ui/core/CssBaseline';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { MapActionContextProvider } from 'lib/map/action-components/contexts';
import { MapActionContext } from 'lib/map/action-components/interfaces';
import createStore from 'lib/map/action-components/store';
import { theme } from 'lib/ui/theme';
import * as React from 'react';

import { AppContextProvider } from './contexts';
import { _Props, _State } from './interfaces';

export class App extends React.Component<_Props, _State> {
  static getDerivedStateFromProps(nextProps: _Props, prevState: _State): null | Partial<_State> {
    if (nextProps.appTheme !== prevState.propTheme) {
      return {
        propTheme: nextProps.appTheme,
        theme: nextProps.appTheme === undefined ? theme : nextProps.appTheme,
      };
    } else {
      return null;
    }
  }

  private _mapContext: MapActionContext;
  private _openSheets: Set<number>;

  constructor(props: _Props) {
    super(props);
    this._mapContext = { store: createStore() };
    this._openSheets = new Set();
    this.state = {
      context: {
        hasNav: false,
        navIsOpen: false,
        setHasNav: this.setNav,
        sheetIsOpen: false,
        toggleNav: this.toggleNav,
        toggleSheet: this.toggleSheet,
        rightIsOpen: false,
        toggleRight: this.toggleRight,
      },
      propTheme: props.appTheme,
      theme: props.appTheme === undefined ? theme : props.appTheme,
    };
  }

  toggleSheet = (id: number, open: boolean) => {
    if (open) {
      this._openSheets.add(id);
    } else {
      this._openSheets.delete(id);
    }
    const sheetIsOpen = this._openSheets.size !== 0;
    this.setState((state) => ({ context: { ...state.context, sheetIsOpen } }));
  }

  toggleNav = (navIsOpen: boolean) => {
    this.setState((state) => ({ context: { ...state.context, navIsOpen } }));
  }

  setNav = (hasNav: boolean) => {
    this.setState((state) => ({ context: { ...state.context, hasNav } }));
  }

  toggleRight = (rightIsOpen: boolean) => {
    this.setState(state => ({ context: { ...state.context, rightIsOpen } }));
  }

  render() {
    const { children, classes } = this.props;
    const { context, theme: stateTheme } = this.state;
    return (
      <MuiThemeProvider theme={stateTheme}>
        <AppContextProvider value={context}>
          <MapActionContextProvider value={this._mapContext}>
            <CssBaseline />
            <div className={classes.root}>
              {children}
            </div>
          </MapActionContextProvider>
        </AppContextProvider>
      </MuiThemeProvider>
    );
  }
}
