import axios, { AxiosError, AxiosResponse } from 'axios';
import { catchError, setSuccess } from 'gridtools/go/utils';
import { SearchError } from 'gridtools/types/go/error';
import { Request } from 'gridtools/types/go/request';
import { Params } from 'gridtools/utils/http';
import { SearchOptions, SearchResult } from 'types/go/sketch';

const error = (err: AxiosError) => catchError<SearchError>(err);
const success = (response: AxiosResponse) => setSuccess<SearchResult[]>(response);

export function search(baseUrl: string, accessToken: string) {
  return (opts: SearchOptions): Request<SearchResult[], SearchError> => {
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const cancel = source.cancel;

    const params = new Params();
    params.set('access_token', accessToken);
    params.set('query', opts.query);
    params.set('scope', 'go_sketch');
    if (opts.limit !== undefined) { params.set('limit', opts.limit.toString()); }
    if (opts.version !== undefined) { params.set('version', opts.version); }
    const url = `${baseUrl}/go_sketches/search?${params.toString()}`;
    const request = axios.get(url, { cancelToken }).then(success).catch(error);

    return { cancel, request };
  };
}
