import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

import { _Props } from './interfaces';

export class Login extends React.Component<_Props> {
  isLoading() {
    return this.props.loading !== undefined && this.props.loading;
  }

  onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.form.onChange({ password: event.target.value });
  }

  onChangeRememberMe = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.form.onChange({ remember: event.target.checked });
  }

  onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.form.onChange({ username: event.target.value });
  }

  onSubmit = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.form.onSubmit();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper className={classes.content}>
          {this.renderHeader()}
          {this.renderForm()}
        </Paper>
      </div>
    );
  }

  renderForm() {
    const { classes } = this.props;
    const { error } = this.props.form;
    return (
      <form onSubmit={this.onSubmit}>
        {error === undefined ? null : <Typography color='error'>{error}</Typography>}
        {this.renderUsername()}
        {this.renderPassword()}
        <div className={classes.bottom}>
          {this.renderRememberMe()}
          {this.renderSubmit()}
        </div>
      </form>
    );
  }

  renderHeader() {
    const { classes, logo } = this.props;
    const title = this.props.text.title;
    return (logo === undefined || logo === null) && (title === undefined || title === null) ? null : (
      <div className={classes.header}>
        <div className={classes.header_left}>
          {title === undefined || title === null ? null : <Typography variant='h6'>{title}</Typography>}
        </div>
        <div className={classes.header_right}>
          {logo === undefined || logo === null ? null : <img className={classes.logo} src={logo} />}
        </div>
      </div>
    );
  }

  renderPassword() {
    const { classes } = this.props;
    const { error, label, placeholder, value } = this.props.form.fields.password;
    const loading = this.isLoading();
    return (
      <TextField
        className={classes.text_field}
        disabled={loading}
        id='go-login-password'
        error={error !== undefined}
        label={label}
        value={value}
        onChange={this.onChangePassword}
        placeholder={placeholder}
        required={true}
        type='password'
        helperText={error}
        fullWidth={true} />
    );
  }

  renderRememberMe() {
    const { classes } = this.props;
    const { label, value } = this.props.form.fields.remember;
    const control = <Checkbox checked={value} onChange={this.onChangeRememberMe} />;
    const loading = this.isLoading();
    return <FormControlLabel className={classes.checkbox} control={control} disabled={loading} label={label}  />;
  }

  renderSubmit() {
    const { classes } = this.props;
    const { submit } = this.props.text;
    const loading = this.isLoading();
    return (
      <div className={classes.loading_wrapper}>
        <Button className={classes.submit} color='primary' variant='contained' type='submit' disabled={loading}>
          {submit}
        </Button>
        {!loading ? null : <CircularProgress size={24} className={classes.loading} />}
      </div>
    );
  }

  renderUsername() {
    const { classes } = this.props;
    const { error, label, placeholder, value } = this.props.form.fields.username;
    const loading = this.isLoading();
    return (
      <TextField
        className={classes.text_field}
        disabled={loading}
        id='go-login-username'
        error={error !== undefined}
        label={label}
        value={value}
        onChange={this.onChangeUsername}
        placeholder={placeholder}
        required={true}
        helperText={error}
        fullWidth={true} />
    );
  }
}
