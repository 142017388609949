import { orderedParentStructure, parentStructure } from 'gridtools/go/telco/fiber_cable';
import { go } from 'gridtools/types';
import { FiberCableObject } from './types';

const fetchResult = <T>(req: go.request.Request<T, any>) => {
  return req.request.then(d => d.type === 'success'
    ? Promise.resolve(d.result)
    : Promise.reject(d));
};

export class ApiHelper {

  constructor(token: string, url = API_URLS.gridoptimizer,
              private readonly parentStruct = parentStructure(url, token),
              private readonly ordered = orderedParentStructure(url, token)) {
  }

  fetchHeader(id: string) {
    return fetchResult(this.parentStruct({ id, details: 'full' }))
      .then(r => r.root.details);
  }

  fetchStructure(id: string, outputGeoms = false) {
    return fetchResult(this.ordered(({ id, details: 'full', output_geoms: outputGeoms })))
      .then(({ lines, objects }) => ({
        lines,
        objects: objects as any as FiberCableObject[]
      }));
  }
}
