import { Feature, FeatureCollection, GeoJSON, Geometry } from 'lib/types/geojson';

import { getBBox } from './bbox';

export type GetProperties<P1 extends object, P2 extends object> = (geometry: Geometry) => P1 | P2;

export interface MergeOptions {
  copy?: boolean;
}

function toFeature<P1 extends object, P2 extends object>(
  data: Feature<P1 | P2> | Geometry,
  getProperties: GetProperties<P1, P2>
): Feature<P1 | P2> {
  if (data.type === 'Feature') {
    return data;
  } else {
    const geometry = data;
    const properties = getProperties(geometry);
    const feature: Feature<P1 | P2> = { type: 'Feature', geometry, properties };
    return feature;
  }
}

function mergeFeatures<P1 extends object, P2 extends object>(
  features1: Array<Feature<P1 | P2>>,
  features2: Array<Feature<P1 | P2>>,
  copy: boolean): Array<Feature<P1 | P2>> {
  if (copy) {
    return [...features1, ...features2];
  } else {
    features2.forEach((feature) => features1.push(feature));
    return features1;
  }
}

// assumes that both objects are using the same projection
export function merge<P1 extends object, P2 extends object>(
  g1: null | undefined | GeoJSON<P1>,
  g2: null | undefined | GeoJSON<P2>,
  getProperties: GetProperties<P1, P2>,
  options?: MergeOptions
) {
  options = options === undefined ? {} : options;
  const copy = options.copy === undefined ? true : options.copy;
  if ((g1 === undefined || g1 === null) && (g2 === undefined || g2 === null)) {
    return null;
  } else if ((g1 === undefined || g1 === null) && !(g2 === undefined || g2 === null)) {
    return g2;
  } else if (!(g1 === undefined || g1 === null) && (g2 === undefined || g2 === null)) {
    return g1;
  } else if ((g1 !== undefined && g1 !== null) && (g2 !== undefined && g2 !== null)) {
    const features: Array<Feature<P1 | P2>> =
        g1.type === 'FeatureCollection' && g2.type === 'FeatureCollection'
      ? mergeFeatures<P1, P2>(g1.features, g2.features, copy)
      : g1.type === 'FeatureCollection' && g2.type !== 'FeatureCollection'
      ? mergeFeatures<P1, P2>(g1.features, [toFeature(g2, getProperties)], copy)
      : g1.type !== 'FeatureCollection' && g2.type === 'FeatureCollection'
      ? mergeFeatures<P1, P2>([toFeature(g1, getProperties)], g2.features, copy)
      : g1.type !== 'FeatureCollection' && g2.type !== 'FeatureCollection'
      ? mergeFeatures<P1, P2>([toFeature(g1, getProperties)], [toFeature(g2, getProperties)], copy)
      : [];
    const collection: FeatureCollection<P1 | P2> = { type: 'FeatureCollection', features };
    collection.bbox = getBBox(collection);
    return collection;
  } else {
    // this case should not be reached
    return null;
  }
}
