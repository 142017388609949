import { configureRoot } from 'app/components/Root';
import { configureStore } from 'app/store/configure';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { reports } from 'app/components/Routes';
import proj from 'ol/proj';
import { configure as configureHotKeys } from 'react-hotkeys';

function prepareProj() {
  const proj4 = require('proj4');
  proj4.defs("EPSG:25832","+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
  proj.setProj4(proj4);
}

// setup app
async function setUp() {
  prepareProj();

  configureHotKeys({
    ignoreTags: []
  });

  const Root = await configureRoot();
  const store = await configureStore();

  const ProvidedRoot = () => (
    <Provider store={store}>
      <Root />
    </Provider>
  );

  ReactDOM.render(
    <Router>
      <Switch>
        {reports}
        <Route component={ProvidedRoot}/>
      </Switch>
    </Router>,
    document.getElementById('app'));
}

setUp();
