import axios, { Canceler } from 'axios';
import * as uuid from 'uuid/v4';
import { error, request } from 'gridtools/types/go';

export class ApiHelper {
  private readonly cancels: { [key: string]: Canceler } = {};
  private transactionId?: number;

  constructor(private readonly baseUrl: string,
              private readonly accessToken: string,
              private readonly user: string) {
  }

  cancel() {
    Object.keys(this.cancels).forEach(p => this.cancels[p]());
    //Object.values(this.cancels).forEach(c => c());
  }

  getMetadata() {
    return {
      context_info: 'GO maps web',
      transaction_date: new Date().toISOString(),
      user: this.user
    };
  }

  uploadFile<T = any>(path: string, file: File, fieldName = 'data', method = 'post'): Promise<T> {
    const fd = new FormData();
    fd.append(fieldName, file, file.name);
    return this.invoke(path, fd, method, 'multipart/form-data');
  }

  invoke<T = any>(path: string, data?: any, method = 'post', contentType = 'application/json'): Promise<T> {
    const url = this.makeUrl(path);
    const headers = { 'Content-Type': contentType };

    const { cancelToken, unregister } = this.register();
    const post = axios
      .request({
        url,
        data,
        method,
        headers,
        cancelToken
      })
      .then(r => r.data);

    post.then(unregister, unregister);

    return post;
  }

  private register() {
    const { cancel, token } = axios.CancelToken.source();

    const key = uuid();
    this.cancels[key] = cancel;

    return {
      unregister: () => delete this.cancels[key],
      cancelToken: token
    };
  }

  private makeUrl(path: string) {
    const sep = path.indexOf('?') < 0 ? '?' : '&';
    let url = this.baseUrl + path.replace(/^\/*/, '/');
    url = `${url}${sep}access_token=${this.accessToken}`;
    if (this.transactionId) {
      url += `&transaction_id=${this.transactionId}`;
    }
    return url;
  }

  async beginTransaction() {
    const { id } = await this.invoke('transactions', { user: this.user });
    this.transactionId = id;
  }

  async endTransaction(commit: boolean) {
    const op = commit ? 'commit' : 'abort';
    if (this.transactionId !== undefined) {
      await this.invoke(`/transactions/${this.transactionId}/${op}`);
    }
    this.transactionId = undefined;
  }

  goRequest<T, E extends error.BaseError>(request: Promise<request.Response<T, E>>): request.Request<T, E> {
    return {
      request,
      cancel: () => this.cancel()
    };
  }
}
