import Axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { startLoading, errorLoading, gotLayers, gotLegend } from 'app/reducers/map/info';

const FORMATS = {
  xml: 'text/xml',
  json: 'application/json',
};
const FILTERABLES = [
  'customer',
  'route',
  'zone',
];

async function fetchGeo(name: string, format: string, params: any) {
  params = {
    request: name,
    version: '1.1.1',
    format,
    ...params
  };

  const response = await Axios.get(API_URLS.wms, { params });

  const contentType = (response.headers['content-type'] || '').replace(';.+', '');
  if (contentType !== format) {
    throw new Error(`${name} returned wrong Content-Type (${contentType}; expected ${format})`);
  }

  return response.data;
}

type LegendItem = {
  layerName: string;
  title: string;
  rules: Array<{
    filter?: string;
    symbolizers: Array<Partial<Record<'Point' | 'Line' | 'Polygon', any>>>;
  }>;
};
type LegendInfo = {
  Legend: LegendItem[];
};

function fetchLegend(layers: string[]) {
  const promises = layers
    .filter(l => FILTERABLES.indexOf(l) >= 0)
    .map(async l => {
      const info: LegendInfo = await fetchGeo('GetLegendGraphic', FORMATS.json, { layer: l });
      return info.Legend.find(leg => leg.layerName === l);
    });
  return Promise.all(promises);
}

export function* fetchLayersSaga() {
  yield put(startLoading());
  try {
    const capabilities: string = yield call(() => fetchGeo('GetCapabilities', FORMATS.xml, {}));

    const layers: string[] = [];
    new DOMParser()
      .parseFromString(capabilities, 'text/xml')
      .querySelectorAll('Capability > Layer > Layer > Name')
      .forEach(l => l.textContent && layers.push(l.textContent.trim()));
    yield put(gotLayers(layers));

    const legend: LegendItem[] = yield call(() => fetchLegend(layers));
    const legendObject: Record<string, any> = {};
    legend.forEach(it => legendObject[it.layerName] = it);
    yield put(gotLegend(legend));

  } catch (e) {
    console.error(e);
    yield put(errorLoading(e));
  }
}
