import { _Dimensions, Unit } from './types';
import { buildPageNumber, convertUnit } from './utils';
import * as JsPDF from 'jspdf';
import { DELTA_Y, MIN_HEIGHT_MM, PAGE, PAGE_NUMBER_ATTR, STYLES, SVG_SHAPE } from './constants';

type Num = number | SVGAnimatedLengthList | SVGAnimatedLength;
type Point<N = Num> = { x: N, y: N };
type Rect<N = Num> = Point<N> & { width: N, height: N };

const convertPixels = (px: number, unit: Unit = 'mm') => convertUnit(px, 'px', unit);

function translate(p: Rect<Num>): Rect<number>;
function translate(p: Num): number;
function translate(p: Point<Num>): Point<number>;
function translate(p: Num | Point<Num> | Rect<Num>): number | Point<number> | Rect<number> {
  if (typeof p === 'number') {
    return convertPixels(p);
  }

  if ('baseVal' in p) {
    const bv = p.baseVal;
    return convertPixels('length' in bv
      ? bv[0].value
      : bv.value);
  }

  const result = {
    x: translate(p.x),
    y: translate(p.y)
  };
  if ('width' in p || 'height' in p)
    return {
      ...result,
      width: translate(p.width),
      height: translate(p.height)
    };
  return result;
}

class PdfHelper {
  private readonly pdf = new JsPDF('landscape', 'mm', PAGE.format).setLineCap('square');
  private readonly thin = convertPixels(1);

  private readonly pageBottom = PAGE.height - PAGE.margin;
  private readonly pageFooterTop: number;

  constructor(private readonly pageBreaks: number[],
              private readonly heights: { report: number, footer: number }) {
    this.pageBreaks = pageBreaks.map(p => translate(p));

    this.pageBreaks.forEach((_, i) => {
      if (i > 0)
        this.pdf.addPage();
    });

    this.pageFooterTop = this.pageBottom - translate(heights.footer);
  }

  borders() {
    this.forEachPage(() => this.pdf
      .setLineWidth(translate(2))
      .rect(
        PAGE.margin,
        PAGE.margin,
        PAGE.width - 2 * PAGE.margin,
        PAGE.height - 2 * PAGE.margin));
  }

  polyline(points: Point[], white: boolean, strokeWidth: number | null, dashed: boolean) {
    for(let i = 1; i < points.length; i++) {
      this.line(points[i - 1], points[i], strokeWidth || 1, white, dashed);
    }
  }

  private testInFooter(...coords: Num[]) {
    const footerTop = translate(this.heights.report - this.heights.footer - 5);
    return coords.some(y => translate(y) >= footerTop);
  }

  private convertFooter(p: Point) {
    const height = translate(this.heights.report);
    const { x, y } = translate(p);

    const ymax = PAGE.height - PAGE.margin;
    return {
      x: x + PAGE.margin,
      y: y + ymax - height
    }
  }

  private forEachPage(action: (page: number) => any) {
    this.pageBreaks.forEach((_, i) => {
      const page = i + 1;
      this.pdf.setPage(page);
      action(page);
    });
  }

  line(from: Point, to: Point, width = 2, white = false, dashed = false) {
    const w = translate(width);

    if (this.testInFooter(from.y, to.y)) {
      const fa = this.convertFooter(from);
      const fb = this.convertFooter(to);

      this.forEachPage(() => this.pdf
        .setDrawColor(white ? 255 : 0)
        .setLineWidth(w)
        .line(fa.x, fa.y, fb.x, fb.y));
      return;
    }


    if (from.y > to.y) {
      [from, to] = [to, from];
    }

    const a = this.tpoint(from, true);
    const b = this.tpoint(to);

    for(let pg = a.page; pg <= b.page; pg++) {
      if (dashed)
        (this.pdf as any).setLineDash([5, 10].map(n => translate(n)), 0)
      else
        (this.pdf as any).setLineDash();
      this.pdf
        .setPage(pg + 1)
        .setDrawColor(white ? 255 : 0)
        .setLineWidth(w)
        .line(
          a.x, pg === a.page ? a.y : PAGE.margin,
          b.x, pg === b.page ? b.y : this.pageFooterTop);
    }
  }

  rect(data: Rect, fill: string) {
    const { x, y, width, height } = this.trect(data, true);
    this.prepareForShape(fill)
      .rect(x, y, width, height, 'FD');
  }

  circle(center: Point, radius: Num, fill: string) {
    const c = this.tpoint(center, true),
      r = translate(radius);
    this.prepareForShape(fill)
      .circle(c.x, c.y, r, 'FD');
  }

  text(p: Point, text: string, fontSize: number, align: string,
       isPageNumber: boolean) {
    const size = convertPixels(fontSize, 'pt');
    const action = (x: number, y: number, t = text) => {
      this.pdf
        .setFontSize(size)
        .text(t, x, y, { align });
    };

    if (this.testInFooter(p.y)) {
      const { x, y } = this.convertFooter(p);
      this.forEachPage(page => action(x, y, isPageNumber
        ? buildPageNumber(page, this.pageBreaks.length)
        : text));
    } else {
      const { x, y } = this.tpoint(p, true);
      action(x, y);
    }
  }

  async image(data: Rect, src: string) {
    const { width, height } = this.trect(data);

    const img = document.createElement('img');
    img.src = src;

    const loading = new Promise(r => img.addEventListener('load', r, { once: true }));
    await loading;

    const padding = 1;
    const ratio = Math.min(
      (width - 2 * padding) / img.width,
      (height - 2 * padding) / img.height);

    const w = img.width * ratio;
    const h = img.height * ratio;

    const dx = (width - w) / 2;
    const dy = (height - h) / 2;

    const { x, y } = this.convertFooter(data);
    this.forEachPage(() => this.pdf
      .addImage(img, 'PNG', x + dx, y + dy, w, h));
  }

  save(filename: string) {
    this.pdf.save(filename);
  }

  private prepareForShape(fill: string) {
    const pdf = this.pdf
      .setLineWidth(this.thin)
      .setFillColor(fill || SVG_SHAPE.fill);
    return (pdf as any).setLineDash() as JsPDF;
  }

  private trect(r: Rect, setPage = false) {
    const t = translate(r);
    return {
      width: t.width,
      height: t.height,
      ...this.onPage(t, setPage)
    };
  }

  private tpoint(p: Point, setPage = false) {
    const t = translate(p);
    return this.onPage(t, setPage);
  }

  private onPage({ x, y }: Point<number>, setPage = false) {
    let page = this.pageBreaks.length - 1;
    for(; page > 0; page--) {
      if (this.pageBreaks[page] <= y)
        break;
    }

    if (setPage) {
      // in jsPDF.setPage() page number is 1-based
      this.pdf.setPage(page + 1);
    }

    return {
      page,
      x: x + PAGE.margin,
      y: y + PAGE.margin - this.pageBreaks[page]
    };
  }
}

const getPoints = (pl: SVGPolylineElement) => {
  const result: DOMPoint[] = [];
  for(let i = 0; i < pl.points.numberOfItems; i++)
    result.push(pl.points.getItem(i));
  return result;
};

const calcPageBreaks = (polylines: NodeListOf<SVGPolylineElement>,
                        { footerHeight }: _Dimensions) => {

  const breakOffset = 20;
  const pageHeight = MIN_HEIGHT_MM - translate(footerHeight);
  const blockHeight = DELTA_Y.down + DELTA_Y.top;

  let currentMaxHeight = pageHeight;

  const pageBreaks = [0];
  let addHeight = 0;
  const testPageBreak = (y: number) => {
    // break page if this block does not fit
    if (translate(y + blockHeight) + addHeight > currentMaxHeight) {
      pageBreaks.push(y);
      addHeight = currentMaxHeight - translate(y); // because translated to this page top
      currentMaxHeight += pageHeight;
    }
  };

  polylines.forEach(pl => {
    const middle = pl.dataset.middle ? parseFloat(pl.dataset.middle) : 0;
    if (middle > 0)
      testPageBreak(middle - breakOffset);
  });

  return pageBreaks;
};

export const createPdf = async (svg: SVGSVGElement, dimensions: _Dimensions) => {

  const links = svg.querySelectorAll('polyline');
  const pageBreaks = calcPageBreaks(links, dimensions);

  const pdf = new PdfHelper(pageBreaks, {
    footer: dimensions.footerHeight,
    report: dimensions.height
  });

  const pendingPromises: Promise<any>[] = [];
  for(let i = 0; i < svg.children.length; i++) {
    const el = svg.children[i];

    switch (el.tagName) {
      case 'text':
        const text = el as SVGTextElement;
        const fontSize = text.style.fontSize && parseFloat(text.style.fontSize)
          || STYLES.text.normal.fontSize;
        const align = text.getAttribute('text-anchor') === 'middle' ? 'center' : 'left';
        const isPageNumber = !!text.dataset[PAGE_NUMBER_ATTR];
        pdf.text(text, el.textContent as any, fontSize, align, isPageNumber);
        break;

      case 'polyline':
        const poly = el as SVGPolylineElement;
        const points = getPoints(poly);
        const white = poly.getAttribute('stroke') === '#fff';
        const width = parseFloat(poly.getAttribute('stroke-width') || '');
        const dashArray = poly.getAttribute("stroke-dasharray");
        pdf.polyline(points, white, Number.isNaN(width) ? null : width, !!dashArray);
        break;

      case 'line':
        const line = el as SVGLineElement;
        pdf.line(
          { x: line.x1, y: line.y1 },
          { x: line.x2, y: line.y2 });
        break;

      case 'circle':
        const c = el as SVGCircleElement;
        pdf.circle({ x: c.cx, y: c.cy }, c.r, c.style.getPropertyValue('fill'));
        break;

      case 'rect':
        const r = el as SVGRectElement;
        pdf.rect(r, r.style.getPropertyValue('fill'));
        break;

      case 'image':
        const img = el as SVGImageElement;
        const pending = pdf.image(img, img.href.baseVal);
        pendingPromises.push(pending);
        break;

      default:
        //console.error('unknown svg element: ' + el.tagName);
        break;
    }
  }

  // don't wait promises too long
  await Promise.race([
    Promise.all(pendingPromises),
    new Promise(r => setTimeout(r, 3000))
  ]);

  pdf.borders();
  return pdf;
};
