import { TEXT_SEARCH_SCOPE } from 'app/constants';
import { setResults } from 'app/reducers/search';
import { search as searchAPI } from 'gridtools/go/telco';
import { UnwrapPromise } from 'gridtools/types/utils';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as docAPI from 'app/pages/Document/api';
import * as sketchAPI from '../pages/sketch/api';
import {search as workorderSearch}  from '../pages/WorkOrder/search';
import { SearchResult, SearchScopeType, StoreState } from 'types';

const SEARCH = 'sagas/search/search';

type SearchCallback = () => void;
type SearchResponse = UnwrapPromise<ReturnType<ReturnType<typeof searchAPI>>['request']>;
type DocResponse = UnwrapPromise<ReturnType<ReturnType<typeof docAPI.search>>['request']>;
type SketchResponse = UnwrapPromise<ReturnType<ReturnType<typeof sketchAPI.search>>['request']>;
type WorkorderResponse = UnwrapPromise<ReturnType<ReturnType<typeof workorderSearch>>['request']>;
type SearchResp = SearchResponse | DocResponse | SketchResponse | WorkorderResponse;

export type SearchAction = {
  callback?: SearchCallback;
  query: string | null;
  scopeType: SearchScopeType;
  type: typeof SEARCH;
};

export type SearchActions =
  | SearchAction;

export function search(query: string | null, scopeType: SearchScopeType, callback?: SearchCallback): SearchAction {
  return { callback, query, scopeType, type: SEARCH };
}

async function searchObjects(query: string, type: SearchScopeType, token: string) {
  const telcoSearch = async (query: string, scope = TEXT_SEARCH_SCOPE) => {
    const telcoSearch = searchAPI(API_URLS.gridoptimizer, token);
    const found = await telcoSearch({ query, scope }).request;
    if (found.type === 'success') {
      found.result = found.result.filter(r => scope.indexOf(r.entity) >= 0);
    }
    return found;
  };

  const addrSearch = type === 'addresses';
  const objSearch = type === 'telco';
  const workordSearch = objSearch && FEATURES.workorders && FEATURES.workorders.enabled &&
    workorderSearch(API_URLS.gridoptimizer, token);
  const sketchSearch = objSearch && FEATURES.sketching && FEATURES.sketching.enabled &&
    sketchAPI.search(API_URLS.gridoptimizer, token);
  const docSearch = objSearch && FEATURES.documents && FEATURES.documents.enabled &&
    docAPI.search(API_URLS.gridoptimizer, token);

  const scope = GO.text_search
    ? TEXT_SEARCH_SCOPE.filter(s => GO.text_search[s] !== false)
    : TEXT_SEARCH_SCOPE;
  const empty = Promise.resolve({ type: 'success' as const, result: [] as any[] });
  const typeOrder = (e: string) =>
      e.startsWith('dk_') ? 2
    : e.startsWith('go_') ? 1 : 0;

  const searchResults: SearchResp[] = await Promise.all([
    objSearch ? telcoSearch(query, scope.filter(s => typeOrder(s) !== 2)) : empty,
    addrSearch ? telcoSearch(query, scope.filter(s => typeOrder(s) === 2)) : empty,
    sketchSearch ? sketchSearch({ query }).request : empty,
    workordSearch ? workordSearch({ query }).request : empty,
    docSearch ? docSearch({ query }).request : empty,
  ]);

  const success = searchResults.some(r => r.type === 'success');

  const results = searchResults.reduce(
    (acc: SearchResult[], res) => res.type === 'success' ? acc.concat(res.result) : acc,
    []);

  const textCompare = (a: string | null, b: string | null) => (a || '').localeCompare(b || '');
  const getScore = (r: SearchResult) => {
    const modifier = typeOrder(r.entity) === 2 ? 5 : 10;
    return Math.round(r.score * modifier);
  };

  const list = results
    .sort((a, b) =>
      (getScore(b) - getScore(a)) ||
      (typeOrder(a.entity) - typeOrder(b.entity)) ||  // dk_ with the same scores go to the end
      textCompare(a.entity, b.entity)
    )
    .slice(0, 100);

  return { success, list };
}

function* searchSaga(action: SearchActions) {
  const token = yield select((state: StoreState) => state.auth.user === null ? null : state.auth.user.token);
  if (token !== null) {
    if (action.query === null) {
      yield put(setResults(null));
    } else {
      try {
        const { query, scopeType, callback } = action;

        type FuncResult = UnwrapPromise<ReturnType<typeof searchObjects>>;
        const { success, list }: FuncResult =
          yield call(() => searchObjects(query.trim(), scopeType, token));
        if (success) {
          yield put(setResults(list));
        }
        if (callback !== undefined) {
          callback();
        }
      } catch {
        // do nothing
      }
    }
  }
}

export default function* mapSaga() {
  yield takeLatest(SEARCH, searchSaga);
}
