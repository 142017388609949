import { BBox, Coordinates, GeoJSON } from 'lib/types/geojson';

export function bboxEquals(b1: BBox | undefined, b2: BBox | undefined,
                           coordEquals?: (a: number | undefined, b: number | undefined) => boolean): boolean {
  return (b1 === undefined && b2 === undefined)
      || (b1 !== undefined && b2 !== undefined && listEquals(b1, b2, coordEquals));
}

export function listEquals<T>(xs: T[], ys: T[], equals: (x: T, y: T) => boolean = (x, y) => x === y): boolean {
  return xs.length === ys.length && xs.every((x, index) => equals(x, ys[index]));
}

export function geojsonEquals(g1: GeoJSON<any>, g2: GeoJSON<any>,
                              coordEquals?: (a: number | undefined, b: number | undefined) => boolean): boolean {
  function coordinatesEquals(cs1: Coordinates, cs2: Coordinates): boolean {
    return listEquals(cs1, cs2, coordEquals);
  }

  function coordinatesArrayEquals(cs1: Coordinates[], cs2: Coordinates[]): boolean {
    return listEquals(cs1, cs2, coordinatesEquals);
  }

  function coordinatesMatrixEquals(cs1: Coordinates[][], cs2: Coordinates[][]): boolean {
    return listEquals(cs1, cs2, coordinatesArrayEquals);
  }

  if (g1.type !== g2.type || !bboxEquals(g1.bbox, g2.bbox, coordEquals)) {
    return false;
  } else if (g1.type === 'Feature' && g2.type === 'Feature') {
    return g1.id === g2.id
        && (
            (g1.geometry === null && g2.geometry === null)
          || (g1.geometry !== null && g2.geometry !== null && geojsonEquals(g1.geometry, g2.geometry))
        );
  } else if (g1.type === 'FeatureCollection' && g2.type === 'FeatureCollection') {
    return listEquals(g1.features, g2.features, (f1, f2) => geojsonEquals(f1, f2));
  } else if (g1.type === 'GeometryCollection' && g2.type === 'GeometryCollection') {
    return listEquals(g1.geometries, g2.geometries, (geo1, geo2) => geojsonEquals(geo1, geo2));
  } else if (g1.type === 'LineString' && g2.type === 'LineString') {
    return coordinatesArrayEquals(g1.coordinates, g2.coordinates);
  } else if (g1.type === 'MultiLineString' && g2.type === 'MultiLineString') {
    return coordinatesMatrixEquals(g1.coordinates, g2.coordinates);
  } else if (g1.type === 'MultiPoint' && g2.type === 'MultiPoint') {
    return coordinatesArrayEquals(g1.coordinates, g2.coordinates);
  } else if (g1.type === 'MultiPolygon' && g2.type === 'MultiPolygon') {
    return listEquals(g1.coordinates, g2.coordinates, (geo1, geo2) => coordinatesMatrixEquals(geo1, geo2));
  } else if (g1.type === 'Point' && g2.type === 'Point') {
    return coordinatesEquals(g1.coordinates, g2.coordinates);
  } else if (g1.type === 'Polygon' && g2.type === 'Polygon') {
    return coordinatesMatrixEquals(g1.coordinates, g2.coordinates);
  } else {
    return false;
  }
}
