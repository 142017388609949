import { go } from 'gridtools/types/index';
import { telco } from 'gridtools/go/index';
import { getColor } from 'app/utils/colors';
import { mapSort } from 'app/utils/lists';

import { formatNumber, NodeAddress, ReportItem } from '../CablePull';
import { toReportItem } from './load';
import { _Fiber, _FiberPort, _Node, NodeDetails, NodeDetailsType } from './types';

const fetchResult = <T>(req: go.request.Request<T, any>) => {
  return req.request.then(d => d.type === 'success'
    ? Promise.resolve(d.result)
    : Promise.reject(d));
};

export class ApiHelper {
  private readonly addressRequests: { [key: string]: Promise<NodeAddress | undefined> } = {};
  private readonly pendingRequests: Promise<any>[] = [];

  constructor(token: string, url = API_URLS.gridoptimizer,
              private readonly outputGeoms: boolean,
              private readonly parentStruct = telco.optical_path.parentStructure(url, token),
              private readonly containerHas = telco.container.has(url, token),
              private readonly routeNodeHas = telco.route_node.has(url, token)) {
  }

  fetchParentStructure = (id: string) =>
    fetchResult(this.parentStruct({ id, details: 'full', output_geoms: this.outputGeoms }));

  startFetchingAddress(details?: NodeDetails | null) {
    if (!details)
      return;

    const { id_source: id, type } = details;
    if (!id || !type)
      return;

    const key = [type, id].join();
    const request = this.addressRequests[key]
      || (this.addressRequests[key] = this.getAddress(id, type));

    this.pendingRequests.push(
      request.then(addr => details.address = addr));
  };

  getPendingRequests = () => Promise.all(this.pendingRequests);

  private async getAddress(id: string, type: NodeDetailsType): Promise<NodeAddress | undefined> {

    const method = type === 'telco_container'
      ? this.containerHas
      : this.routeNodeHas;
    const addr = await fetchResult(method({ details: 'full', id }));

    const address = addr.dk_access_address && addr.dk_access_address[0];
    return address && {
      id: address.id_source,
      address: address.details.address_name,
    };
  };
}

type IdObject = _Fiber | _FiberPort;
export class IdentificationBuilder {
  private readonly objects: Record<number, IdObject[]> = {};

  append(sequence: number, obj: IdObject) {
    const array = this.objects[sequence] || (this.objects[sequence] = []);
    if (array.every(t => t.id_source !== obj.id_source))
      array.push(obj);
  }

  build(sequence: number): string {
    const arr = this.getObjects(sequence);
    return arr.map(o => o.details.identification).filter(Boolean).join(',');
  }

  getObjects(sequence: number) {
    const array = this.objects[sequence] || [];
    return mapSort(array, t => IdentificationBuilder
      .parseNumber(t.details.identification || ''));
  }

  private static parseNumber(s: string) {
    let x = parseInt(s);
    if (!isNaN(x)) return x;

    const num = s.replace(/^\D*/, '');
    x = parseInt(num);
    if (!isNaN(x)) return x;

    return s;
  }
}

export const prepareReportData = (items: _Node[]) => {
  const result: ReportItem[] = [];
  const lineColor = getColor('telco_fiber_cable');

  items.forEach((node, i, list) => {
    const item = toReportItem(node, i, list);
    if (item) {
      const { fiber = [], ...it } = item;

      const dataItem: ReportItem = {
        ...it,
        lineColor,
        objectType: node.type,
        getLineText: l => [
          item.below,
          l && `(${formatNumber(l)} m)`
        ].filter(Boolean).join(' '),
        objects: {
          line: fiber as any,
          item: node.objects as any,
        },
        address: node.details && 'address' in node.details ? node.details.address : undefined,

        ...{ sequence: node.sequence },
      };

      const first = (node.objects || []).find(t =>
        t.type === 'telco_fiber_port' ||
        t.type === 'telco_customer' ||
        t.type === 'telco_container' ||
        t.type === 'telco_optical_splice');
      if (first) {
        dataItem.objectType = first.type === 'telco_container' && first.details.type || first.type;
      }

      result.push(dataItem);
    }
  });

  return result;
};
